import { Backdrop, Button, Typography, CircularProgress } from "@mui/material";
import { Box, useTheme } from "@mui/system";

const ApprovalDialog = function ({ onClose, onConfirm, isConfirming = false }: any) {
  const theme = useTheme();

  return (
    <Backdrop open sx={{ backdropFilter: "blur(6px)", zIndex: 1000 }}>
      <Box
        textAlign="center"
        sx={{
          padding: "40px",
          borderRadius: "20px",
          backgroundColor: theme.palette.primary.light,
          maxWidth: "720px",
        }}
      >
        <Typography
          sx={{ fontWeight: 500, fontSize: "18px", marginBottom: "24px", color: theme.palette.secondary.main }}
        >
          Approve your wallet for trades in the ForeverBots collection
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "18px",
            marginBottom: "40px",
            color: theme.palette.secondary.main,
          }}
        >
          To continue, you must approve your wallet for trades within the ForeverBots collection. You will only need to
          approve this transaction once.
        </Typography>
        <Box gap="10px" display="flex" justifyContent="center">
          <Box
            sx={{
              position: "relative",
              width: "100%",
              display: "flex",
              flexWarp: "wrap",
              gap: "20px",
              justifyContent: "center",
            }}
          >
            <Button variant="cancel" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={onConfirm}
              disabled={isConfirming}
              sx={{ display: "inline-flex", position: "relative" }}
            >
              {isConfirming && (
                <CircularProgress
                  color="success"
                  size={24}
                  sx={{
                    "& svg": {
                      marginRight: 0,
                      marginLeft: 0,
                    },
                    top: "5px",
                    left: "0",
                    position: "relative",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                    marginRight: "12px",
                  }}
                />
              )}
              Confirm
            </Button>
          </Box>
        </Box>
      </Box>
    </Backdrop>
  );
};

export default ApprovalDialog;
