import { Card, CardContent, CardMedia, Typography, Button } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import useNFT from "hooks/useNFT";
import Skeleton from "@mui/material/Skeleton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const NFTCard = function ({
  frontpage,
  registryAddress,
  tokenId,
  selected,
  onSelect,
  showSelect = false,
  maxWidth = 225.2,
}: any) {
  const theme = useTheme();
  const nft = useNFT(registryAddress, tokenId);

  return (
    <Card
      sx={{
        overflow: "visible",
        marginBottom: "10px",
        width: frontpage ? "100%" : maxWidth,
        height: frontpage ? "calc(100% + 80px)" : maxWidth,
        maxWidth: frontpage ? "416px" : maxWidth,
        backgroundColor: "transparent",
        borderRadius: "0",
        border: selected ? `1px solid ${theme.palette.secondary.dark}` : "1px solid transparent",
        opacity: 0.9,
        "& .showOnHover": {
          visibility: "hidden",
        },
        "&:hover .showOnHover": {
          visibility: "visible",
        },
        "&:hover": {
          opacity: 1,
        },
        boxShadow: "none",
        cursor: "pointer",
        backgroundImage: "none",
      }}
      onClick={() => {
        if (showSelect) onSelect();
      }}
    >
      {!nft ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          sx={{
            width: frontpage ? "100%" : maxWidth,
            height: frontpage ? "100%" : maxWidth,
            maxWidth: frontpage ? "416px" : maxWidth,
            aspectRatio: "1",
          }}
        />
      ) : (
        <>
          <CardMedia
            component="img"
            image={nft.imageUrl}
            alt={nft.name}
            sx={{
              width: frontpage ? "100%" : maxWidth,
              height: frontpage ? "100%" : maxWidth,
              maxWidth: frontpage ? "416px" : maxWidth,
              aspectRatio: "1",
            }}
          />
          <CardContent
            sx={{
              display: "flex",
              flexWrap: "wrap",
              backgroundColor: theme.palette.primary.light,
              padding: "10px 12px",
              paddingLeft: "0px !important",
              paddingBottom: "10px !important",
              alignItems: "center",
              boxShadow: "none",
              gap: "20px",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h5" color="white" display="flex" alignItems="center" fontSize={16}>
                #{nft.tokenId} FOREVERBOT
              </Typography>
            </Box>
            {!selected && showSelect && (
              <Box sx={{ display: "flex", alignItems: "center" }} className="showOnHover">
                <CheckCircleOutlineIcon sx={{ color: "secondary.dark", fontSize: "25px" }} />
              </Box>
            )}
            {selected && showSelect && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CheckCircleIcon sx={{ color: "secondary.dark", fontSize: "25px" }} />
              </Box>
            )}
          </CardContent>
        </>
      )}
    </Card>
  );
};

export default NFTCard;
