import { initializeApp } from "firebase/app";
import {
  collection,
  doc,
  setDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  serverTimestamp,
  limit,
} from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD-l3mfvvFo-nk9IXopQEfkVAoly9vbMDA",
  authDomain: "uglypool.firebaseapp.com",
  projectId: "uglypool",
  storageBucket: "uglypool.appspot.com",
  messagingSenderId: "629332441356",
  appId: "1:629332441356:web:74befb81915ae2ef1a7129",
  measurementId: "G-C46JER4CBW",
};
const app = initializeApp(firebaseConfig, "uglypool");
const db = getFirestore(app);

export async function getActivities(chainId: number | undefined) {
  const activitiesRef = collection(db, chainId === 4 ? "activities_rinkeby" : "activities");
  const q = await query(activitiesRef, orderBy("createdAt", "desc"), limit(30));
  const acitivtySnapshot = await getDocs(q);
  return acitivtySnapshot.docs.map((doc: any) => doc.data());
}

export async function addActivity(document: any, chainId: number | undefined) {
  let activityRef;
  // add code for mainnet and use txHash as docId to avoid duplications
  const activitiesRef = chainId === 4 ? "activities_rinkeby" : "activities";
  try {
    activityRef = await setDoc(doc(db, activitiesRef, document.data.txHash), {
      ...document,
      createdAt: serverTimestamp(),
    });
  } catch (e) {
    console.log(e);
  }
  return activityRef;
}
