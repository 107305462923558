import { useEffect, useState } from "react";
import { Container, Box, useMediaQuery, Button, Typography, IconButton } from "@mui/material";
import { ethers } from "ethers";
import { useActiveWeb3React } from "hooks/web3";
import { useTheme } from "@mui/system";
import { UGLYPOOL_CONTRACT_ADDRESS } from "config/misc";
import abiUglyPool from "abi/uglypool.json";
import NFTCard from "components/NFTCard";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Skeleton from "@mui/material/Skeleton";
import useNFT from "hooks/useNFT";
import { formatAddress } from "utils";
import { allNFTsByWallet } from "utils/helper";

interface Props {
  visible: boolean;
  contractAddress: string;
  action: string;
  heading: { text: string; bold?: string };
  selectedPoolNFT: number;
  onClickBackBtn: () => void;
  onChooseNFT: (nft: any) => void;
}

const Index = function ({
  visible,
  action,
  heading,
  contractAddress,
  selectedPoolNFT,
  onChooseNFT,
  onClickBackBtn,
}: Props) {
  const [nfts, setNFTs] = useState<any>([]);
  const [registryName, setRegistryName] = useState("");
  const [selectedMyNfts, selectMyNfts] = useState<any>([]);

  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up(720));

  const { library, account } = useActiveWeb3React();
  const signer = library?.getSigner();
  const contract = new ethers.Contract(UGLYPOOL_CONTRACT_ADDRESS, abiUglyPool, signer);
  const poolNFT = useNFT(contractAddress, selectedPoolNFT);

  const getNFTs = async function (account: string, contractAddress: string) {
    const NFTs = await allNFTsByWallet(account, contractAddress);
    return NFTs || [];
  };

  const loadMyNFT = async (account: string, contractAddress: string) => {
    if (!contractAddress) {
      alert("Please input collection address");
      return;
    }
    const res = await contract.registryName(contractAddress);
    setRegistryName(res);
    const tokens = await getNFTs(account, contractAddress);
    setNFTs(tokens);
  };

  useEffect(() => {
    if (account) {
      loadMyNFT(account, contractAddress);
    }
  }, [account, visible]);

  useEffect(() => {
    selectMyNfts([]);
  }, [visible]);

  const isMultipleSelectable = action === "withdraw" || action === "deposit";

  return (
    <Box
      sx={{
        position: "fixed",
        display: visible ? "block" : "none",
        top: 0,
        left: 0,
        bgcolor: "background.default",
        bottom: 0,
        right: 0,
        zIndex: 10,
      }}
    >
      <Container sx={{ maxWidth: "1294px!important" }}>
        <Box sx={{ mt: "30px", width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textTransform: "none",
              "&:hover": { backgroundColor: "unset" },
              color: "#333333",
              fontWeight: "500",
              fontSize: "30px",
              lineHeight: "35px",
            }}
          >
            <Box
              style={{ cursor: "pointer" }}
              onClick={() => {
                onClickBackBtn();
              }}
              ml="-24px"
            >
              <ChevronLeftIcon sx={{ color: "#F7ECC2" }} />
            </Box>
            {!poolNFT ? (
              <Skeleton
                animation="wave"
                variant="rectangular"
                sx={{ width: "100px", height: "100px", borderRadius: "20px" }}
              />
            ) : (
              <img
                style={{ width: "100px", height: "100px", borderRadius: "20px" }}
                alt="Pool NFT"
                src={poolNFT.imageUrl}
              />
            )}
            <Box ml="27px">
              <Typography variant="h1" color="secondary">
                {heading.text} &nbsp;
                {heading.bold && (
                  <Typography component="span" color="secondary.dark" variant="h1">
                    {heading.bold}
                  </Typography>
                )}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ mt: "20px", py: "20px", px: "16px", borderRadius: "20px", backgroundColor: "primary.main" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="h5" color="secondary.light">
              Select NFT from your wallet{" "}
              <Typography component="span" color="secondary.main">
                {formatAddress(account || "")}
              </Typography>{" "}
              to swap into the pool
            </Typography>

            <Button
              variant="contained"
              disabled={!selectedMyNfts.length}
              onClick={() => {
                if (selectedMyNfts.length === 1) {
                  onChooseNFT(selectedMyNfts[0]);
                }
              }}
              sx={{ height: "40px" }}
            >
              Continue
            </Button>
          </Box>
          <Box
            gap="19px"
            display="flex"
            flexWrap="wrap"
            mt="20px"
            pb="10px"
            justifyContent={isMedium ? "initial" : "center"}
            sx={{ height: "300px", overflow: "auto" }}
          >
            {nfts.map((item: number) => (
              <NFTCard
                key={item}
                registryAddress={contractAddress}
                registryName={registryName}
                tokenId={item}
                maxWidth={225}
                showSelect
                selected={selectedMyNfts.indexOf(item) > -1}
                onSelect={() => {
                  const index = selectedMyNfts.indexOf(item);
                  if (index > -1) {
                    selectedMyNfts.splice(index, 1);
                  } else if (isMultipleSelectable) {
                    selectedMyNfts.push(item);
                  } else {
                    selectMyNfts([item]);
                    return;
                  }
                  selectMyNfts([...selectedMyNfts]);
                }}
              />
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Index;
