import * as React from "react";

const WalletContext = React.createContext({
  walletBalance: 0 as number,
  setWalletBalance: (balance: number) => {
    /**/
  },
  account: "" as null | string | undefined,
});
export default WalletContext;
