import { S3_IMAGES_JPG_URL, UGLYPOOL_CONTRACT_ADDRESS } from "config/misc";
import { ethers } from "ethers";
import abiRegular from "abi/regulars.json";
import { createAlchemyWeb3 } from "@alch/alchemy-web3";

export type PoolObject = {
  id: number;
  registry: string;
  registryName?: string;
  logo?: string;
  owner: string;
  ownerENS?: string;
  price: number;
  treasury: number;
  random: boolean;
  nfts: any[];
  fee: number;
};
export async function setApproval(account: any, library: any, registry: any, notifyHash: any, txType: string) {
  const signer = library?.getSigner();
  const nftContract = new ethers.Contract(registry, abiRegular, signer);
  const isApproved = await nftContract.isApprovedForAll(account, UGLYPOOL_CONTRACT_ADDRESS);
  if (!isApproved) {
    try {
      const tx = await nftContract.setApprovalForAll(UGLYPOOL_CONTRACT_ADDRESS, true);
      notifyHash(tx.hash, txType);
      return 1;
    } catch (e) {
      console.log(e);
    }
  }
  return 0;
}

export function getS3ImageByTokenId(tokenId: number) {
  return `${S3_IMAGES_JPG_URL}${tokenId}?alt=media`;
}

const alcheUrl =
  process.env.REACT_APP_UGLY_CONTRACT_ADDRESS?.toLocaleLowerCase() ===
  "0xc2760aEd8BD6dd45676Ca565Cd5eAcbB2803987a".toLocaleLowerCase()
    ? "https://eth-mainnet.g.alchemy.com/v2/C3TQE3JEcky_r8zfz0y08_YQShTY4y7P"
    : "https://eth-rinkeby.alchemyapi.io/v2/ml82PmYrEPF4I2RSjqQcgHht1mR6OOy4";
// Using HTTPS
const web3 = createAlchemyWeb3(alcheUrl);
export const allNFTsByWallet = async (account: string, contractAddress: string) => {
  const nftsForOwner =
    (await web3.alchemy.getNfts({ owner: account, contractAddresses: [contractAddress] })).ownedNfts || [];
  const nftIds = nftsForOwner.map((item: any) => {
    const number = ethers.BigNumber.from(item.id.tokenId).toNumber();
    return number;
  });
  return nftIds;
};
