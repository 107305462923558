import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import ConnectWalletButton from "components/ConnectWalletButton";
import { useActiveWeb3React } from "hooks/web3";

const MenuItemWrapper = styled.button`
  border: none;
  padding-right: 12px;
  padding-top: 14px;
  padding-left: 14px;
  padding-bottom: 16px;
  min-width: 120px;
  border-radius: 20px;
  background-color: ${({ theme }: any) => theme.palette.primary.main};
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  letter-spacing: 0.5px;
  color: ${({ theme }: any) => theme.palette.secondary.light};
  &.active {
    background-color: ${({ theme }: any) => theme.palette.primary.light};
  }
  & svg {
    margin-right: 20px;
  }
  &:hover {
    background-color: ${({ theme }: any) => theme.palette.primary.light};
    text-decoration: underline;
  }
  &:disabled {
    background-color: ${({ theme }: any) => theme.palette.primary.dark};
    pointer-events: none;
  }
  @media screen and (max-width: 1160px) {
    font-size: 26px;
  }
  @media screen and (max-width: 870px) {
    & span {
      display: none;
    }
    & svg {
      margin-right: 10px;
    }
  }
  @media screen and (max-width: 950px) {
    display: none;
  }
`;
const Header = function () {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1030));
  const isXS = useMediaQuery(theme.breakpoints.down(550));
  const { account } = useActiveWeb3React();

  const location = useLocation();
  const pathName = location.pathname;

  return (
    <Box sx={{ mt: "20px", mb: "14px", height: "56px" }} display="flex" gap="15px">
      <Box sx={{ flexGrow: 1, cursor: "pointer" }} display="flex" alignItems="center" onClick={() => navigate("/")}>
        <Typography
          variant={isSmallScreen ? "h2" : "h1"}
          color="secondary"
          sx={{
            display: isXS ? "none" : "block",
            "&:hover": {
              textDecoration: "underline",
            },
            width: "120px",
          }}
          onClick={() => window.open("https://foreverbots.io")}
        >
          FOREVERBOTS™
        </Typography>
      </Box>
      <Box
        style={{
          display: isSmallScreen ? "none" : "block",
        }}
      >
        <MenuItemWrapper
          onClick={() => {
            window.open("https://opensea.io/collection/foreverbots");
          }}
        >
          OPENSEA
        </MenuItemWrapper>
      </Box>
      <Box
        style={{
          display: isSmallScreen ? "none" : "block",
        }}
      >
        <MenuItemWrapper
          onClick={() => {
            window.open("https://foundation.app/collection/foreverbots");
          }}
        >
          FOUNDATION
        </MenuItemWrapper>
      </Box>
      <Box
        style={{
          display: isSmallScreen ? "none" : "block",
        }}
      >
        <MenuItemWrapper
          onClick={() => {
            window.open("https://twitter.com/foreverbots");
          }}
        >
          TWITTER
        </MenuItemWrapper>
      </Box>
      {/* <Box>
        <MenuItemWrapper
          className={pathName === "/swap" || pathName === "/" ? "active" : ""}
          onClick={() => navigate("/swap")}
        >
          Swap
        </MenuItemWrapper>
      </Box>
      <Box>
        <MenuItemWrapper className={pathName === "/activity" ? "active" : ""} onClick={() => navigate("/activity")}>
          Activity
        </MenuItemWrapper>
      </Box> */}
      <Box sx={{ zIndex: 2 }}>
        <ConnectWalletButton />
      </Box>
    </Box>
  );
};

export default Header;
