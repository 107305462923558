import { useState, useEffect } from "react";
import { useActiveWeb3React } from "hooks/web3";
import { getS3ImageByTokenId } from "utils/helper";
import { ethers } from "ethers";
import { UGLYPOOL_CONTRACT_ADDRESS } from "config/misc";
import abiUglyPool from "../abi/uglypool.json";

export const getNFTById = async (contract: any, registryAddress: string, tokenId: number) => {
  const tokenURI = await contract.tokenURI(registryAddress, tokenId);
  const json = tokenURI.replace("ipfs://", "");
  const res = await fetch(`https://ipfs.io/ipfs/${json}`);
  const tokenObj = await res.json();

  const imageHash = tokenObj.image.replace("ipfs://", "");
  tokenObj.image = `https://ipfs.io/ipfs/${imageHash}`;

  return {
    tokenId,
    name: tokenObj.name,
    imageUrl: tokenObj.image,
  };
};

// ###################
// This is only meant for Regular ugly pool, should not used for uglypool (with multiple collections)
// ###################
// ###################
const getDirtyNFTById = (tokenId: number) => ({
  tokenId,
  name: `Regular #${tokenId}`,
  imageUrl: getS3ImageByTokenId(tokenId),
});

const useNFT = (registryAddress: string, tokenId: number) => {
  const { library } = useActiveWeb3React();
  const [nft, setNft] = useState<any>(null);

  useEffect(() => {
    let isSubscribed = true;
    const getNFT = async () => {
      const contract = new ethers.Contract(UGLYPOOL_CONTRACT_ADDRESS, abiUglyPool, library);
      try {
        const nft: any = await getNFTById(contract, registryAddress, tokenId);
        if (isSubscribed) {
          setNft(nft);
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (tokenId != null && tokenId > 0) {
      getNFT();
    }
    // if (tokenId !== null && tokenId > -1) {
    //   setNft(getDirtyNFTById(tokenId));
    // }
    return () => {
      isSubscribed = false;
    };
  }, [library, tokenId, registryAddress]);
  return nft;
};
export default useNFT;
