export const formatAddress = (addr: string) => {
  if (addr) {
    if (addr.length > 16) return `${addr.slice(0, 10)}...${addr.slice(addr.length - 3, addr.length)}`;
    return addr;
  }
  return "";
};

export const classNames = (...classes: string[]) => classes.filter(Boolean).join(" ");

export default { formatAddress };
