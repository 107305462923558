import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    cancel: true;
  }
}
declare module "@mui/material/styles" {
  interface TypographyVariants {
    smallHeading: any;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    smallHeading?: any;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    smallHeading: true;
  }
}
// A custom theme for this app
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1258,
      xl: 1536,
    },
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&$checked": {
            color: "#3D70B2",
          },
        },
        checked: {},
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "15px",
          padding: "11px 12px",
          borderRadius: "100px",
          fontWeight: 500,
          textTransform: "none",
          width: "100%",
          maxWidth: "195px",
        },
      },
      variants: [
        {
          props: { variant: "cancel" },
          style: {
            color: "#000202",
            backgroundColor: "#5A5954",
          },
        },
        {
          props: { variant: "contained" },
          style: {
            color: "#000202",
            backgroundColor: "#0082F0",
            display: "inline-flex",
            alignItems: "center",
            letterSpacing: "0.5px",
            border: "2px solid #0082F0",
            "& svg": {
              marginRight: "6px",
              marginLeft: "-10px",
            },
            ":hover": {
              backgroundColor: "#0082F0",
            },
            ":disabled": {
              backgroundColor: "background: rgba(0, 2, 2, 0.6)",
              border: "none",
              padding: "13px 14px",
              color: "#000202",
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            color: "#0082F0",
            backgroundColor: "transparent",
            display: "inline-flex",
            alignItems: "center",
            letterSpacing: "0.5px",
            border: "2px solid #0082F0",
            "& svg": {
              marginRight: "6px",
              marginLeft: "-10px",
            },
            ":hover": {
              backgroundColor: "#0082F0",
              color: "black",
            },
          },
        },
      ],
    },
  },
  palette: {
    background: {
      default: "#262626",
    },
    mode: "dark",
    primary: {
      main: "#262626",
      dark: "#262626",
      light: "#262626",
    },
    secondary: {
      main: "#0082F0",
      dark: "#0082F0",
      light: "#0082F0",
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    fontFamily: ["HelveticaNeueLTS"].join(","),
    fontSize: 16,
    button: {
      fontSize: 15,
      fontWeight: 500,
      lineHeight: "20px",
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "16px",
      letterSpacing: "0.5px",
    },
    h5: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0.5px",
    },
    h4: {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: "21px",
    },
    h3: {
      fontSize: 21,
      fontWeight: 500,
      lineHeight: "25px",
    },
    h2: {
      fontSize: 30,
      lineHeight: "45px",
      fontWeight: 500,
    },
    h1: {
      fontSize: 30,
      fontWeight: 500,
      lineHeight: "45px",
    },
    smallHeading: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: "28px",
    },
  },
});

export const GREYCOLOR = "#5A5954";

export default theme;
