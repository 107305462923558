import { useCallback, useEffect } from "react";
import { Button } from "@mui/material";
import { isMobile } from "react-device-detect";

import WalletMenu from "components/Menu/WalletMenu";
import { injected, walletconnect } from "../../connectors";
import { useActiveWeb3React } from "../../hooks/web3";

const ConnectWalletButton = function () {
  const { account, activate } = useActiveWeb3React();

  useEffect(() => {
    if (account) {
      /* */
    }
  }, [account]);

  const handleConnect = useCallback(async () => {
    if (isMobile) {
      window.location.assign("https://metamask.app.link/dapp/hangar.foreverbots.io/");
      return;
    }
    const connector = isMobile ? walletconnect : injected;

    await activate(connector, (err: any) => {
      console.log(err);
    });
  }, [activate]);

  if (!account) {
    return (
      <div>
        <Button
          size="large"
          variant="contained"
          color="secondary"
          onClick={handleConnect}
          sx={{ height: "100%", fontSize: "14px", borderRadius: "38px", width: "181px", marginTop: "6px" }}
        >
          CONNECT_TO_WALLET
        </Button>
      </div>
    );
  }

  return <WalletMenu />;
};

export default ConnectWalletButton;
