import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as fb from "lib/firebase";
import { Box, Button, Icon, Avatar, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Link from "@mui/material/Link";
import moment from "moment";
import { useActiveWeb3React } from "hooks/web3";
import { formatAddress } from "utils";
import { PoolID } from "config/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { getS3ImageByTokenId } from "utils/helper";

const transformWalletToENS = async (activities: any, library: any) => {
  const updatedActivities: any = [];
  for (let i = 0; i < activities.length; i += 1) {
    let { owner } = activities[i].data;
    try {
      owner = (await library?.lookupAddress(activities[i].data.owner || "")) || activities[i].data.owner || "";
    } catch (e) {
      console.log(e);
    }
    updatedActivities.push({ ...activities[i], data: { ...activities[i].data, owner } });
  }
  return updatedActivities;
};

const Activity = function ({ activities, setActivities, pool }: any) {
  const { account, chainId, library } = useActiveWeb3React();
  const location = useLocation();
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up(720));
  const navigate = useNavigate();
  const CutomTableHeaderCell = styled(TableCell)(({ theme }) => ({
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "12px",
    color: "#fff",
    letterSpacing: "0.5px",
  }));

  const CutomTableBodyCell = styled(TableCell)(({ theme }) => ({
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "11px",
    color: "#fff",
  }));

  React.useEffect(() => {
    (async () => {
      try {
        const activities = await fb.getActivities(chainId);
        setActivities(activities.filter((activity: any) => activity.data?.registryName === "ForeverBots"));
        if (chainId === 1) {
          setActivities(
            await transformWalletToENS(
              activities.filter((activity: any) => activity.data?.registryName === "ForeverBots").slice(0, 30),
              library
            )
          );
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [account, chainId, location.pathname]);

  return (
    <Box style={{ maxWidth: "1300px", width: "100%", marginInline: "auto" }} mt="34px" mb="120px">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          marginBottom: !isMedium ? "40px" : "150px",
          gap: "20px",
        }}
      >
        <Box>
          <Typography>SWAP POOL SIZE:</Typography>
          <Typography fontSize="92px" lineHeight={1} fontFamily="NeuePixelGrotesk">
            {pool?.nfts?.length || 0}
          </Typography>
        </Box>
        <Box
          style={{
            textAlign: "right",
            flex: 1,
          }}
        >
          <Button variant="contained" style={{ marginRight: "9px", marginBottom: "9px" }}>
            ACTIVITY
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              navigate("/");
            }}
            style={{
              marginRight: "9px",
              marginBottom: "9px",
            }}
          >
            SWAP_NOW
          </Button>
        </Box>
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 650, fontSize: "16px" }} aria-label="simple table">
          <TableHead sx={{ color: "#fff" }}>
            <TableRow>
              <CutomTableHeaderCell>Wallet</CutomTableHeaderCell>
              <CutomTableHeaderCell>Deposited to Pool</CutomTableHeaderCell>
              <CutomTableHeaderCell>Received from Pool</CutomTableHeaderCell>
              <CutomTableHeaderCell>Time</CutomTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activities.map(
              (activity: any) =>
                activity.name.includes("Selected Swap") && (
                  <TableRow key={activity.data.txHash}>
                    <CutomTableBodyCell sx={{ fontWeight: "bold" }}>
                      {formatAddress(activity.data.owner)}
                    </CutomTableBodyCell>
                    <CutomTableBodyCell>
                      <Box display="flex" flexDirection="row" alignItems="center">
                        <Avatar
                          src={activity.data.myNFTImage}
                          sx={{ mr: 2, width: 40, height: 40 }}
                          variant="rounded"
                        />
                        {activity.data.registryName} #{activity.data.myNFT}
                      </Box>
                    </CutomTableBodyCell>
                    <CutomTableBodyCell>
                      <Box display="flex" flexDirection="row" alignItems="center">
                        <Avatar
                          src={activity.data.poolNFTImage}
                          sx={{ mr: 2, width: 40, height: 40 }}
                          variant="rounded"
                        />
                        {activity.data.registryName} #{activity.data.poolNFT}
                      </Box>
                    </CutomTableBodyCell>

                    <CutomTableBodyCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography sx={{ display: "inline-flex" }}>
                          {moment(new Date(activity.createdAt.seconds * 1000)).fromNow()}
                        </Typography>
                        <Link
                          href={`https://${chainId === 1 ? "" : "rinkeby."}etherscan.io/tx/${activity.data.txHash}`}
                          target="_blank"
                          rel="noopener"
                          sx={{ display: "inline-flex", alignItems: "center", ml: "13px" }}
                        >
                          <Icon component={OpenInNewIcon} sx={{ color: "#F6ECC2", width: "15px" }} />
                        </Link>
                      </Box>
                    </CutomTableBodyCell>
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default Activity;
