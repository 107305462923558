export const dummy = {
  nft: {
    wallet: "0x43453ASsd434AVB4344343845dc",
    balance: 99233,
    imageUrl: "https://regulars-nft.s3.eu-west-1.amazonaws.com/med/med_cc_2840.jpg",
    name: "Regular #2840",
  },
};

export const PoolID = 7;
