import { useEffect, useMemo, useState } from "react";
import { Container } from "@mui/material";
import { ethers } from "ethers";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import { useActiveWeb3React } from "hooks/web3";
import { PoolObject } from "utils/helper";
import Header from "components/Header";
import Pool from "pages/Pools/Pool";
import Activity from "pages/Activity";
import WalletContext from "context/WalletContext";
import Web3ReactManager from "./components/Web3ReactManager";
import LogoSVG from "./assets/logo.svg";

const App = function () {
  const { account } = useActiveWeb3React();
  const [activities, setActivities] = useState<any>([]);
  const [pool, setPool] = useState<PoolObject | null>(null);

  const [walletBalance, setWalletBalance] = useState(0);
  const value = useMemo(() => ({ walletBalance, setWalletBalance, account }), [walletBalance, account]);
  return (
    <Web3ReactManager>
      <Container
        style={{
          width: "100%",
          maxWidth: "100%",
        }}
      >
        <WalletContext.Provider value={value}>
          <Header />
          <div onClick={() => window.open("https://foreverbots.io/")} style={{ marginBottom: "120px" }}>
            <img
              src={LogoSVG}
              style={{
                width: "100%",

                cursor: "pointer",
              }}
              alt="logo"
            />
          </div>
          <Routes>
            <Route path="/" element={<Pool pool={pool} setPool={setPool} />} />
            <Route path="/swap" element={<Pool pool={pool} setPool={setPool} />} />
            <Route
              path="/activity"
              element={<Activity activities={activities} setActivities={setActivities} pool={pool} />}
            />
          </Routes>
        </WalletContext.Provider>
      </Container>
    </Web3ReactManager>
  );
};

export default App;
