import * as React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

import { Web3ReactProvider, createWeb3ReactRoot } from "@web3-react/core";

import { BrowserRouter } from "react-router-dom";
import { NetworkContextName } from "./config/misc";
import theme from "./config/theme";
import getLibrary from "./utils/getLibrary";

import App from "./App";

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

if (window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false;
}

ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <Web3ProviderNetwork getLibrary={getLibrary}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </Web3ProviderNetwork>
  </Web3ReactProvider>,
  document.querySelector("#root")
);
