import Dialog from "components/Dialog";
import { Typography, Button, useMediaQuery, IconButton, Stack, Box } from "@mui/material";
import NFTCard from "components/NFTCard";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";

interface Props {
  open: boolean;
  showSwapResult: boolean;
  isTxGoing: boolean;
  buyNFT: {
    fee: number;
    tokenId: number;
    registry: string;
    registryName: string;
  };
  sellNFT: {
    tokenId: number;
    registry: string;
    registryName: string;
  };
  onClose: () => void;
  handleSwap: () => void;
}

const useStyles = makeStyles((theme: any) => ({
  dialogSmallTxt: {
    color: "#F6ECC2",
    marginTop: "30px",
    marginBottom: "10px",
    textAlign: "center",
    lineHeight: "24px",
    fontSize: "16px",
  },
  fixMobile: {
    [theme.breakpoints.down(500)]: {
      flexWrap: "wrap",
      gap: "10px",
      "& img": {
        width: "150px",
        height: "150px",
      },
    },
  },
}));

const Index = function ({ open, isTxGoing, showSwapResult, buyNFT, sellNFT, onClose, handleSwap }: Props) {
  const classes = useStyles();

  useEffect(() => {
    if (showSwapResult) {
      setTimeout(() => onClose(), 3000);
    }
  }, [showSwapResult]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      {!showSwapResult ? (
        <Box textAlign="center">
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Typography
              sx={{
                alignItems: "center",
                color: "secondary.main",
                fontWeight: "500",
                fontSize: "30px",
                lineHeight: "35px",
              }}
            >
              Swapping for &nbsp;
              <Typography
                component="span"
                sx={{ color: "secondary.dark", fontSize: "inherit", fontWeight: "inherit", lineHeight: "inherit" }}
              >
                {buyNFT.registryName} #{buyNFT.tokenId}
              </Typography>
            </Typography>

            <IconButton
              onClick={() => {
                onClose();
              }}
            >
              <CloseIcon sx={{ color: "#F7ECC2" }} />
            </IconButton>
          </Box>

          <Stack direction="row" gap="30px" justifyContent="center" mb="30px" className={classes.fixMobile}>
            <Stack>
              <Typography className={classes.dialogSmallTxt}>Pool NFT</Typography>
              <NFTCard registryAddress={buyNFT.registry} registryName={buyNFT.registryName} tokenId={buyNFT.tokenId} />
            </Stack>
            <Stack>
              <Typography className={classes.dialogSmallTxt}>My NFT</Typography>
              <NFTCard
                registryAddress={sellNFT.registry}
                registryName={sellNFT.registryName}
                tokenId={sellNFT.tokenId}
              />
            </Stack>
          </Stack>

          {!isTxGoing ? (
            <Button variant="contained" onClick={() => handleSwap()} style={{ marginTop: "10px" }}>
              Confirm and Swap Now
            </Button>
          ) : (
            <Typography sx={{ color: "secondary.dark", textAlign: "center", marginTop: "10px" }}>
              Processing Transaction...
            </Typography>
          )}
        </Box>
      ) : (
        <Box>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: "30px", mt: "10px" }}>
            <Typography
              sx={{
                alignItems: "center",
                fontWeight: "500",
                fontSize: "30px",
                lineHeight: "35px",
              }}
              color="secondary"
            >
              You received &nbsp;
              <Typography
                component="span"
                sx={{ color: "secondary.dark", fontSize: "inherit", fontWeight: "inherit", lineHeight: "inherit" }}
              >
                ForeverBots #{buyNFT.tokenId}!
              </Typography>
            </Typography>

            <IconButton
              onClick={() => {
                onClose();
              }}
            >
              <CloseIcon sx={{ color: "#F7ECC2" }} />
            </IconButton>
          </Box>
          <Box sx={{ width: "340px", mx: "auto", display: "flex", justifyContent: "center", mb: "30px" }}>
            <NFTCard
              key={buyNFT.tokenId}
              registryAddress={buyNFT.registry}
              registryName={buyNFT.registryName}
              tokenId={buyNFT.tokenId}
              maxWidth={225}
            />
          </Box>
          <Typography sx={{ color: "secondary.dark", textAlign: "center" }}>Your swap was successful!</Typography>
        </Box>
      )}
    </Dialog>
  );
};

export default Index;
