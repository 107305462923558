import { Menu, Close } from "@mui/icons-material";
import { Collapse, List, ListItem, ListItemText, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import { ethers } from "ethers";
import { useActiveWeb3React } from "hooks/web3";
import { useEffect, useRef, useState, useContext } from "react";
import abi from "abi/RegularDollars.json";
import { REG_TOKEN_v3_CONTRACT_ADDRESS } from "config/misc";
import WalletContext from "context/WalletContext";
import { useNavigate } from "react-router-dom";
import ListItems from "./ListItems";

const WalletMenu = function () {
  const theme = useTheme();
  const isSX = useMediaQuery(theme.breakpoints.down(550));
  const isMedium = useMediaQuery(theme.breakpoints.down(1030));
  const [open, setOpen] = useState(false);
  const [ensName, setEnsName] = useState("");
  const { account, library } = useActiveWeb3React();
  const menuRef = useRef<any>(null);
  const navigate = useNavigate();

  const { walletBalance, setWalletBalance } = useContext(WalletContext);

  useEffect(() => {
    if (account) {
      (async () => {
        try {
          setEnsName((await library?.lookupAddress(account || "")) || account || "");
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, [account, library]);

  const handleClickOutsideMenu = function (event: any) {
    if (menuRef && !menuRef?.current?.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutsideMenu);
    return () => window.removeEventListener("click", handleClickOutsideMenu);
  }, []);

  return (
    <List
      ref={menuRef}
      sx={{
        overflow: "hidden",
        backgroundColor: !open ? "none" : theme.palette.primary.main,
        py: 0,
        borderRadius: "20px",
        marginTop: isSX ? "10px" : 0.5,
        pointerEvents: isMedium ? "all" : "none",
      }}
      onClick={() => {
        setOpen(!open);
        if (!isMedium) {
          navigate("/");
        }
      }}
    >
      <ListItem
        button
        alignItems="center"
        sx={{
          borderRadius: "20px",
          backgroundColor: !open ? "none" : theme.palette.primary.main,
          ":hover": { backgroundColor: theme.palette.primary.light },
          justifyContent: "right",
          width: "160px",
        }}
      >
        <ListItemText
          style={{ lineHeight: "16px !important", textAlign: "right", display: "flex", alignItems: "center" }}
          primary={<span style={{ fontSize: "30px", lineHeight: "30px" }}>{formatWalletAddress(ensName)}</span>}
          sx={{
            my: 0,
            color: theme.palette.secondary.dark,
            fontSize: "12px !important",
            height: "40px",
          }}
        />
        {/* {isMedium ? (
          open ? (
            <Close style={{ pointerEvents: "none", marginLeft: "20px", color: theme.palette.secondary.light }} />
          ) : (
            <Menu style={{ pointerEvents: "none", marginLeft: "20px", color: theme.palette.secondary.light }} />
          )
        ) : null} */}
      </ListItem>
      {/* <Collapse in={open} timeout="auto" unmountOnExit style={{ width: "110px" }}>
        <ListItems
          items={[
            { label: "OPENSEA", link: "https://opensea.io/collection/foreverbots" },
            { label: "FOUNDATION", link: "https://foundation.app/collection/foreverbots" },
            { label: "TWITTER", link: "https://twitter.com/foreverbots" },
            { label: formatWalletAddress(ensName) },
          ]}
        />
      </Collapse> */}
    </List>
  );
};

export default WalletMenu;

const formatWalletAddress = (address: string) => {
  if (address.length < 20) return address;
  return `${address.slice(0, 4)}...${address.slice(address.length - 3, address.length)}`;
};
