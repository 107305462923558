import * as React from "react";
import { Box, useTheme } from "@mui/system";
import { Typography, Button, useMediaQuery } from "@mui/material";
import { useActiveWeb3React } from "hooks/web3";
import { UGLYPOOL_CONTRACT_ADDRESS, DAPP_ID } from "config/misc";
import { DEFAULT_CHAIN_ID } from "config/chains";
import abiUglyPool from "abi/uglypool.json";
import { ethers } from "ethers";
import { setApproval } from "utils/helper";
import WalletContext from "context/WalletContext";
import NFTCard from "components/NFTCard";
import Notify from "bnc-notify";
import * as fb from "lib/firebase";
import { getNFTById } from "hooks/useNFT";
import { useState } from "react";
import SwapDialog from "components/Dialog/SwapDialog";
import { PoolID } from "config/constants";
import { useNavigate, useLocation } from "react-router-dom";
import ApprovalDialog from "components/Dialog/ApprovalDialog";
import abiRegular from "abi/regulars.json";
import MyNFTPicker from "./MyNFTPicker";

const Pool = function ({ pool, setPool }: any) {
  const [swapPoolNFT, setSwapPoolNFT] = React.useState(-1);
  const [swapMyNFT, setSwapMyNFT] = React.useState(-1);
  const [showApprovalDialog, setShowApprovalDialog] = React.useState<number | null>(null);
  const [isApproving, setIsApproving] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [registryName, setRegistryName] = React.useState("");

  const [notify, setNotify] = React.useState<any>(null);
  const [isTxGoing, setIsTxGoing] = React.useState<boolean>(false);
  const [contractListener, setContractListener] = React.useState<any>(null);
  const [showSwapResult, setShowSwapResult] = useState<boolean>(false);
  const [visibleConfirmSwapDialog, setVisibleConformSwapDialog] = useState<boolean>(false);

  const { setWalletBalance } = React.useContext(WalletContext);

  const { library, account, chainId } = useActiveWeb3React();
  const theme = useTheme();
  const provider = !account ? library : library?.getSigner();
  const contract = new ethers.Contract(UGLYPOOL_CONTRACT_ADDRESS, abiUglyPool, provider);
  const isMedium = useMediaQuery(theme.breakpoints.up(720));
  const id = PoolID;

  const [visibleMyNFTPicker, openMyNFTPicker] = React.useState({ visible: false, action: "swap" });

  const notifyHash = (hash: any, txType: string) => {
    const { emitter } = notify.hash(hash);

    emitter.on("txSent", console.log);
    emitter.on("txPool", console.log);
    emitter.on("txConfirmed", async () => {
      if (!pool) return;
      try {
        switch (txType) {
          case "approvalForSwap": {
            let tx;
            if (!pool.random) {
              tx = await contract.swapSelectedUgly(pool.id, swapMyNFT, swapPoolNFT, {
                value: ethers.utils.parseEther(pool.fee.toString()),
              });
            } else {
              tx = await contract.swapRandomUgly(pool.id, swapMyNFT);
            }
            notifyHash(tx.hash, "swapNFT");
            break;
          }
          default:
            break;
        }
      } catch (e) {
        console.log(e);
        setIsTxGoing(false);
      }
    });
    emitter.on("txSpeedUp", console.log);
    emitter.on("txCancel", console.log);
    emitter.on("txFailed", console.log);
  };

  const handleSwap = async () => {
    if (!pool) return;
    setIsTxGoing(true);
    try {
      const res = await setApproval(account, library, pool.registry, notifyHash, "approvalForSwap");
      if (!res) {
        let tx;
        if (!pool.random) {
          tx = await contract.swapSelectedUgly(pool.id, swapMyNFT, swapPoolNFT, {
            value: ethers.utils.parseEther(pool.fee.toString()),
          });
        } else {
          tx = await contract.swapRandomUgly(pool.id, swapMyNFT);
        }
        notifyHash(tx.hash, "swapNFT");
      }
    } catch (e) {
      setIsTxGoing(false);
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (!pool) {
      return () => {
        contract.removeAllListeners("TradeExecuted");
      };
    }

    if (!contractListener) {
      const listener = contract.on(
        "TradeExecuted",
        (poolId: ethers.BigNumber, user: string, myNFT: ethers.BigNumber, poolNFT: ethers.BigNumber, log: any) => {
          if (user !== account || poolId.toNumber() !== pool.id) return;

          getNFTById(contract, pool.registry, myNFT.toNumber()).then(myNFTObj => {
            getNFTById(contract, pool.registry, poolNFT.toNumber()).then(async poolNFTObj => {
              await fb.addActivity(
                {
                  name: pool.random ? "Random Swap" : "Selected Swap",
                  data: {
                    myNFT: myNFT.toNumber(),
                    myNFTImage: myNFTObj.imageUrl,
                    poolNFT: poolNFT.toNumber(),
                    poolNFTImage: poolNFTObj.imageUrl,
                    registry: pool.registry,
                    registryName: pool.registryName,
                    owner: account,
                    txHash: log.transactionHash,
                    poolId: PoolID,
                  },
                },
                chainId
              );
              navigate("/activity");
            });
          });

          setShowSwapResult(true);

          let { nfts } = pool;
          nfts = nfts.filter((nft: number) => nft !== poolNFT.toNumber());
          nfts.push(myNFT.toNumber());

          setIsTxGoing(false);
          setPool({ ...pool, nfts });
          setContractListener(listener);
        }
      );
    }
    return () => {
      contract.removeAllListeners("TradeExecuted");
    };
  }, [pool]);

  React.useEffect(() => {
    if (account && notify === null) {
      const notifyObj = Notify({
        dappId: DAPP_ID,
        networkId: DEFAULT_CHAIN_ID,
        darkMode: true,
        onerror: error => console.log(`Notify error: ${error.message}`),
      });
      setNotify(notifyObj);
    }

    (async () => {
      const _pool = await contract.pools(PoolID);
      const res = await contract.registryName(_pool.registry);
      setRegistryName(res);
      const nfts = await contract
        .getPoolNFTIds(id)
        .then((ids: ethers.BigNumber[]) => ids.map((id: ethers.BigNumber) => id.toNumber()))
        .catch((e: Error) => console.log(e));
      const validTokenId = chainId === 1 ? 44 : 1;
      const firstNFT = await getNFTById(contract, _pool.registry, validTokenId);

      const newValue = {
        ..._pool,
        id,
        treasury: _pool.treasury / 10 ** 18,
        price: _pool.price / 10 ** 18,
        fee: _pool.fee / 10 ** 18,
        nfts,
        logo: firstNFT.imageUrl,
        registryName: res,
      };
      setPool(newValue);
    })();

    // dont retrieve Ethereum from wallet (we show only REG Dollars)
    // (async () => {
    //   const balance = account && provider ? await provider.getBalance(ethers.utils.getJsonWalletAddress(account)) : 0;
    //   setWalletBalance(+ethers.utils.formatEther(balance));
    // })();
  }, [location.pathname]);

  const onChooseNFT = async (nftId: any) => {
    if (!pool) return;
    if (nftId === -1) {
      alert("Please select your nft");
      return;
    }
    const signer = library?.getSigner();
    const nftContract = new ethers.Contract(pool.registry, abiRegular, signer);
    const isApproved = await nftContract.isApprovedForAll(account, UGLYPOOL_CONTRACT_ADDRESS);
    if (!isApproved && showApprovalDialog === null) {
      setShowApprovalDialog(nftId);
      return;
    }
    if (visibleMyNFTPicker.action === "swap") {
      setSwapMyNFT(nftId);
      setVisibleConformSwapDialog(true);
    }
  };

  if (!pool || !registryName) return null;
  const getNftPickerHeading = (action: string) => {
    if (action === "swap") {
      return {
        text: "Swapping for",
        bold: `ForeverBots #${swapPoolNFT}`,
      };
    }

    return { text: "heading" };
  };

  const approveContract = async (nftId: number) => {
    if (!pool) return;
    try {
      const signer = library?.getSigner();
      const nftContract = new ethers.Contract(pool.registry, abiRegular, signer);
      const tx = await nftContract.setApprovalForAll(UGLYPOOL_CONTRACT_ADDRESS, true).then((tx: any) => {
        setIsApproving(true);
        return tx.wait();
      });
      if (tx) {
        setShowApprovalDialog(null);
        setIsApproving(false);
        onChooseNFT(nftId);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box
      style={{
        maxWidth: "1300px",
        width: "100%",
        marginInline: "auto",
      }}
    >
      <Box mt="40px" mb="120px">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            marginBottom: !isMedium ? "40px" : "120px",
            gap: "25px",
          }}
        >
          <Box>
            <Typography>SWAP POOL SIZE:</Typography>
            <Typography fontSize="92px" lineHeight={1} fontFamily="NeuePixelGrotesk">
              {pool?.nfts?.length || 0}
            </Typography>
          </Box>
          <Box
            style={{
              textAlign: "right",
              flex: 1,
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                navigate("/activity");
              }}
              style={{ marginRight: "9px", marginBottom: "9px" }}
            >
              ACTIVITY
            </Button>
            <Button
              variant="contained"
              style={{
                marginRight: "9px",
                marginBottom: "9px",
              }}
            >
              SWAP_NOW
            </Button>
          </Box>
        </Box>
        <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="flex-end">
          <Typography>SWAP POOL:</Typography>
          <Button
            variant="contained"
            disabled={swapPoolNFT === -1}
            onClick={() => {
              openMyNFTPicker({ action: "swap", visible: true });
            }}
          >
            SWAP_IN_POOL
          </Button>
        </Box>

        <Box
          gap="25px"
          display="flex"
          flexWrap="wrap"
          mt="15px"
          pb="10px"
          justifyContent="center"
          // style={{ maxHeight: "calc(100vh - 240px)", overflow: "auto" }}
        >
          {pool.nfts.map((id: number) => (
            <NFTCard
              frontpage
              key={id}
              registryAddress={pool.registry}
              registryName={registryName}
              tokenId={id}
              selected={swapPoolNFT === id}
              showSelect={!pool.random && !!account}
              onSelect={async () => {
                if (swapPoolNFT === id) {
                  setSwapPoolNFT(-1);
                } else {
                  setSwapPoolNFT(id);
                }
              }}
            />
          ))}
        </Box>
      </Box>

      <MyNFTPicker
        visible={visibleMyNFTPicker.visible}
        action={visibleMyNFTPicker.action}
        contractAddress={pool.registry}
        heading={getNftPickerHeading(visibleMyNFTPicker.action)}
        selectedPoolNFT={swapPoolNFT}
        onClickBackBtn={() => {
          openMyNFTPicker({ ...visibleMyNFTPicker, visible: false });
        }}
        onChooseNFT={onChooseNFT}
      />

      {/* Selected Swap modal */}
      <SwapDialog
        open={visibleConfirmSwapDialog}
        onClose={() => {
          setSwapMyNFT(-1);
          setVisibleConformSwapDialog(false);
          setShowSwapResult(false);
        }}
        isTxGoing={isTxGoing}
        handleSwap={handleSwap}
        buyNFT={{ tokenId: swapPoolNFT, registryName, registry: pool.registry, fee: pool.fee }}
        sellNFT={{ tokenId: swapMyNFT, registryName, registry: pool.registry }}
        showSwapResult={showSwapResult}
      />
      {showApprovalDialog !== null && (
        <ApprovalDialog
          onClose={() => {
            setShowApprovalDialog(null);
            setIsApproving(false);
          }}
          onConfirm={() => approveContract(showApprovalDialog)}
          isConfirming={isApproving}
        />
      )}
    </Box>
  );
};
export default Pool;
